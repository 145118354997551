import React from 'react'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment-timezone'
const PlanDetails = (props) => {
  const { planInfo, form } = props
  const { translate } = useTranslation()
  const location = {
    virtualClassroom: 'Virtual Classroom',
    zinkerzPlatform: translate('modal.newplan.plandetails.zinkerzp'),
    hangouts: 'Meets',
    zoom: 'Zoom',
    inPerson: translate('modal.newplan.plandetails.inperson')
  }
  return (
    <div className='d-flex flex-column w-100'>
      <h3 className='font-weight-bold text-dark my-3'>{translate('plans.modal.details')}</h3>
      <span className='h3 text-logo'>{planInfo.name}</span>
      <span className='h4 text-gray font-weight-light'>{translate('modal.childPlan.rangeDates', { from: moment(form.from).utc().format('MMM DD, YYYY'), to: moment(form.to).utc().format('MMM DD, YYYY') })}</span>
      <span className='h4 text-gray font-weight-light'>{`${translate(`plans.${planInfo.testDate ? 'testDate' : 'fixedParts'}`)} | ${location[planInfo.location.type] || 'No Location Assigned'}`}</span>
      <div className='borderTop my-2' />
    </div>
  )
}

export default PlanDetails
