export default {
  misc: {
    location: {
      en: {
        virtualClassroom: 'Virtual Classroom',
        zinkerzPlatform: 'Zinkerz platform',
        hangouts: 'Meets',
        zoom: 'Zoom',
        inPerson: 'In-person'
      },
      es: {
        virtualClassroom: 'Aula virtual',
        zinkerzPlatform: 'Plataforma de Zinkerz',
        hangouts: 'Meets',
        zoom: 'Zoom',
        inPerson: 'Presencial'
      }
    }
  },
  profile: {
    payments: {
      en: {
        endOf: (str) => `End of ${str}`
      },
      es: {
        endOf: (str) => `Fines de ${str}`
      }
    },
    actions: {
      changeNotifications: {
        en: {
          reminderMinutes: (num) => `${num} min before`
        },
        es: {
          reminderMinutes: (num) => `${num} minutos antes`
        }
      }
    }
  },
  program: {
    plan: {
      infoModal: {
        en: {
          individualTopicLessons: ({ num, topic }) => `${num} Individual ${topic} Lessons`,
          totalTopicLessons: ({ num, topic }) => `${num} ${topic} Lessons`,
          groupalTopicLessons: ({ num, topic }) => `${num} Groupal ${topic} Lessons`,
          partDuration: ({ from, to }) => `From ${from} to ${to}`
        },
        es: {
          individualTopicLessons: ({ num, topic }) => `${num} Lecciones Individuales de ${topic}`,
          totalTopicLessons: ({ num, topic }) => `${num} Lecciones de ${topic}`,
          groupalTopicLessons: ({ num, topic }) => `${num} Lecciones Grupales de ${topic}`,
          partDuration: ({ from, to }) => `Desde ${from} al ${to}`
        }
      }
    }
  },
  modal: {
    childPlan: {
      en: {
        rangeDates: ({ from, to }) => `${from} to ${to}`
      },
      es: {
        rangeDates: ({ from, to }) => `${from} hasta ${to}`
      }
    },
    newplan: {
      recommendEducators: {
        en: {
          Individual: ({ name }) => `Recommend Individual ${name} educator`,
          Group: ({ name }) => `Select Groupal ${name} educator`
        },
        es: {
          Individual: ({ name }) => `Recomienda un educador para ${name}`,
          Group: ({ name }) => `Selecciona un educador de ${name} para clases Grupales`
        }
      },
      scheduledetails: {
        en: {
          totalLesson: ({ name }) => `Total individual ${name} lessons`,
          individualPerWeek: ({ name }) => `Individual ${name} lessons per week`,
          groupalPerWeek: ({ name }) => `Groupal ${name} lessons per week`,
          willHaveLessons: ({ total }) => `The student will have ${total} lessons`
        },
        es: {
          totalLesson: ({ name }) => `Total de lecciones individuales de ${name}`,
          individualPerWeek: ({ name }) => `Lecciones individuales de ${name} por semana`,
          groupalPerWeek: ({ name }) => `Lecciones grupales de ${name} por semana`,
          willHaveLessons: ({ total }) => `El estudiante tendrá ${total} lecciones`
        }
      },
      en: {
        indexFee: ({ num1, num2 }) => {
          const string =
            num1 +
            (['th', 'st', 'nd', 'rd'][((num1 % 100) - 20) % 10] ||
              ['th', 'st', 'nd', 'rd'][num1 % 100] ||
              ['th', 'st', 'nd', 'rd'][0])
          return `${string} out of ${num2}`
        }
      },
      es: {
        indexFee: ({ num1, num2 }) => `${num1}° de ${num2}`
      }
    },
    draftplan: {
      en: {
        individualLesson: ({ num, name }) => `${num} individual ${name} lessons`,
        groupalLesson: ({ num, topic }) => `${num} Groupal ${topic} Lessons`,
        rangeDates: ({ from, to }) => `FROM ${from} TO ${to}`,
        indexFee: ({ num1, num2 }) => `${num1}st out of ${num2}`
      },
      es: {
        individualLesson: ({ num, name }) => `${num} lecciones individuales de ${name}`,
        groupalLesson: ({ num, topic }) => `${num} lecciones grupales de ${topic}`,
        rangeDates: ({ from, to }) => `DESDE ${from} HASTA ${to}`,
        indexFee: ({ num1, num2 }) => `${num1}° de ${num2}`
      }
    },
    sendnotes: {
      en: {
        charsLeft: ({ num }) => `${num} chars left`
      },
      es: {
        charsLeft: ({ num }) => `Faltan ${num} caracteres`
      }
    },
    infolesson: {
      en: {
        haspending: ({ name, num }) => `${name} has ${num} pending lessons`
      },
      es: {
        haspending: ({ name, num }) => `${name} tiene ${num} lecciones pendientes`
      }
    },
    lesson: {
      scheduleConflict: {
        en: {
          chooseDateTopic: ({ topic }) => `Choose a date for your ${topic} lesson`
        },
        es: {
          chooseDateTopic: ({ topic }) => `Selecciona un horario para tu lección de ${topic}`
        }
      }
    }
  }
}
