import React, { useState, useMemo, useEffect } from 'react'
import {
  Modal,
  IconClose,
  Input,
  SelectOption,
  StickyButton,
  ListItem,
  Avatar,
  IconSignCheck,
  IconSignPlus,
  IconSignMinus,
  DropDown,
  CheckboxOption,
  IconArrowRight,
  Button
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import { FormGroup } from 'reactstrap'
import SendLessonNotesMutation from '../../../../Model/Lessons/SetLessonNotes/index.js'
import moment from 'moment'
import useTranslation from '../../../../i18n/useTranslation'
// Consts
const charsAcqMin = 125
const charsEffortMin = 100
const charsScoreMax = 15

const SendLessonNotes = (props) => {
  const { dataLesson, refetchCalendarQuery } = props
  const [isOpen, setOpen] = useState(false)
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const toggle = () => {
    setOpen(!isOpen)
  }
  const [form, setForm] = useState({
    didHw: null,
    newHw: false
  })

  const setNewDataLesson = (newDataLesson) => {
    // setea nuevos datos de la lección en redux
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.infoLessonModal',
      data: {
        isOpen: true,
        dataLesson: newDataLesson
      }
    })
  }

  const { submit: sendLessonNotesSubmit, loading: loadMutation } = SendLessonNotesMutation(
    (result) => {
      // setea nuevos datos de la lección en redux
      setNewDataLesson(result?.data?.setLessonNotes)
      // actualiza calendario
      refetchCalendarQuery()
      // cierra modal
      toggle()
      // reiniciar formulario
      setForm({
        didHw: null,
        newHw: false
      })
    },
    () => {
      alert('error')
    }
  )

  useEffect(() => {
    if (isOpen) {
      if (dataLesson?.notes) {
        setForm({
          ...dataLesson?.notes?.educatorNotes,
          dueDateHw:
            dataLesson?.notes?.educatorNotes?.dueDateHw
              ? moment(dataLesson?.notes?.educatorNotes?.dueDateHw).format('YYYY-MM-DD')
              : null
        })
      } else {
        // reiniciar formulario
        setForm({
          didHw: null,
          newHw: false
        })
      }
    }
  }, [isOpen, dataLesson])

  const student = useMemo(() => {
    const student = dataLesson ? (dataLesson.student || dataLesson.studentPlan || dataLesson.studentGroup) : null
    if (!Array.isArray(student)) {
      return [student]
    }
    return student
  }, [dataLesson])

  const educator = useMemo(() => {
    return dataLesson?.substitute || dataLesson?.educator
  }, [dataLesson])
  const [educatorAttendance, setEducatorAttendance] = useState(
    educator?.attendanceStatus === 'checkIn'
      ? 'attended'
      : educator?.attendanceStatus === 'pending'
        ? 'missed'
        : educator?.attendanceStatus
  )

  const [studentAttendance, setStudentAttendance] = useState(student.reduce((atte, stu) => {
    return {
      ...atte,
      [stu?.studentUserId]: 
        stu?.attendanceStatus === 'checkIn'
          ? 'attended'
          :  stu?.attendanceStatus === 'pending'
            ? 'missed'
            : stu?.attendanceStatus
    }
  }, {}))

  useEffect(() => {
    // ACTUALIZA LOS ATTENDANCE SI SE MODIFICA dataLesson EN REDUX
    if (student && educator && isOpen) {
      setStudentAttendance(student.reduce((atte, stu) => {
        return {
          ...atte,
          [stu.studentUserId]: 
            stu.attendanceStatus === 'checkIn'
              ? 'attended'
              : stu.attendanceStatus === 'pending'
                ? 'missed'
                : stu.attendanceStatus
        }
      }, {}))
      setEducatorAttendance(educator?.attendanceStatus)
    }
  }, [student, educator, isOpen])

  const parseDataToMutationLessonNotes = (
    data,
    lessonData,
    studentAtte,
    status,
    isGroup,
    allStudentMissed
  ) => {
    let obj = {}
    if (data?.cantcomplete || allStudentMissed) {
      /**
       * => cuando la leccion NO se completó
       *  attendanceStudents: [AttendanceStudentsInput!]!
          status: NoteStatus!
          lostReason: String

          acqKnowledge: String!
          newHw: Boolean!
          descriptionHw: String!
          dueDateHw: DateTime!
          others: String!
      * **/
      obj = {
        educatorAttendance,
        lessonNoteInput: {
          attendanceStudents: Object.keys(studentAtte).map((idStu) => {
            return (
              {
                studentId: idStu,
                status: studentAtte[idStu]
              }
            )
          }),
          status: status, //allStudentMissed && !data?.cantcomplete ? status : 'draft',
          lostReason: data?.lostReason || null,
          studentEffort: '',
          acqKnowledge: '',
          newHw: false,
          descriptionHw: '',
          dueDateHw: moment().toISOString(),
          others: ''
        },
        lessonId: lessonData?.id
      }
      /** if (data?.didHw !== null) {
        obj = {
          ...obj,
          lessonNoteInput: {
            ...obj.lessonNoteInput,
            didHw: data?.didHw,
            hadHw: true
          }
        }
      }**/
    } else {
      /**
       * => cuando la leccion se completó
       *  attendanceStudents: [AttendanceStudentsInput!]!
          status: NoteStatus!
          hadHw: Boolean
          didHw: Boolean
          score: Float
          studentEffort: String
          acqKnowledge: String!
          newHw: Boolean!
          descriptionHw: String!
          dueDateHw: DateTime!
          others: String!
          lostReason: String
      * **/
      obj = {
        educatorAttendance,
        lessonNoteInput: {
          attendanceStudents: Object.keys(studentAtte).map((idStu) => {
            return (
              {
                studentId: idStu,
                status: studentAtte[idStu]
              }
            )
          }),
          status: status,
          studentEffort: data?.studentEffort || '',
          acqKnowledge: data?.acqKnowledge,
          newHw: data?.newHw || false,
          descriptionHw: data?.descriptionHw || '',
          dueDateHw: data?.dueDateHw ? moment(data.dueDateHw).toISOString() : moment().toISOString(),
          others: data?.others || ''
        },
        lessonId: lessonData?.id
      }
      if (data?.didHw !== null && !isGroup) {
        obj = {
          ...obj,
          lessonNoteInput: {
            ...obj.lessonNoteInput,
            didHw: data?.didHw || false,
            scoreAlphanumeric: data?.scoreAlphanumeric || null,
            hadHw: true
          }
        }
      }
    }
    return obj
  }
  const setAttendanceOfStudent = (stuId, value) => {
    setStudentAttendance(
      {
        ...studentAttendance,
        [stuId]: value
      }
    )
  }
  const isGroupLesson = dataLesson?.__typename === 'GroupLesson'

  // SE VUELVE true CUANDO TODOS LOS ESTUDIANTES TIENEN ATTENDANCE missed
  const allStudentMissed = Object.values(studentAttendance).reduce((allMissed, stuAttendance) => {
    if (stuAttendance !== 'missed') {
      return false
    }
    return allMissed
  }, true)

  let validation = useMemo(() => {
    let valid = true
    if (form?.cantcomplete) {
      valid = valid &&
          Object.keys(studentAttendance).reduce((validAccu, idStu) => {
            if (studentAttendance[idStu] !== 'missed') {
              return false
            }
            return validAccu
          }, true) &&
          educatorAttendance === 'attended' &&
          (form?.lostReason !== undefined && form?.lostReason !== null && form?.lostReason !== '')
    } else if (!allStudentMissed && !form?.cantcomplete) {
      valid =
        valid &&
        Object.keys(studentAttendance).reduce((validAccu, idStu) => {
          if (studentAttendance[idStu] === "pending") return false;
          return validAccu;
        }, true) &&
        educatorAttendance !== "pending" &&
        form?.acqKnowledge !== undefined &&
          form?.acqKnowledge !== null &&
          charsAcqMin <= form?.acqKnowledge?.length;
      if (!isGroupLesson) {
        valid =
          valid &&
          form?.studentEffort !== undefined &&
            form?.studentEffort !== null &&
            charsEffortMin <= form?.studentEffort?.length;
      }
      if (form?.newHw === true) {
        valid =
          valid &&
          form?.descriptionHw !== undefined && form?.descriptionHw !== null &&
          form?.dueDateHw !== undefined && form?.dueDateHw !== null;
      }
      if (form?.didHw !== null && !isGroupLesson) {
        valid =
          valid &&
          form?.didHw !== undefined &&
          form?.didHw !== null &&
          (form?.didHw !== true ||
            (form?.scoreAlphanumeric !== undefined &&
              form?.scoreAlphanumeric !== null &&
              form?.scoreAlphanumeric !== "" &&
              form?.scoreAlphanumeric?.length <= charsScoreMax));
        //(form?.score !== undefined && form?.score !== null && form?.score !== '')
      }
    } else if (allStudentMissed) {
      valid =
        valid &&
        educatorAttendance === 'attended' &&
        (form?.lostReason !== undefined && form?.lostReason !== null && form?.lostReason !== '')
    }
    return valid
  }, [form, studentAttendance, educatorAttendance, isGroupLesson, allStudentMissed])

  return (
    <>
      <div className="m-0 p-0" onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className="modal-colored modal-centered fixed-head-modal"
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className="modal-header px-6 pt-6 pb-4 d-flex flex-row justify-content-between">
            <span className="h2 font-weight-bold">
              {translate("modal.sendnotes.title")}
            </span>
            <IconClose onClick={toggle} />
          </div>
        }
      >
        <div className="m-0 px-6">
          <FormGroup className="w-100 d-flex flex-column">
            <div className="d-flex flex-row my-4 pl-2">
              <CheckboxOption
                name="cantcomplete"
                onChange={(e) => {
                  setForm({
                    ...form,
                    cantcomplete: e,
                  });
                }}
              />
              <span>{translate("modal.sendnotes.cantcomplete")}</span>
            </div>
            <div className="borderTop" />
            <div>
              {student.map((stu, index) => {
                return (
                  <ListItem
                    showBottomLine={false}
                    showTopLine={false}
                    className="py-3"
                    Left={
                      <div className="d-flex flex-row">
                        <Avatar
                          name={`${stu?.firstName} ${stu?.lastName} `}
                          named={false}
                          uri={null}
                          size="avatar-small mr-1"
                        />
                        <span className="h45 ml-2 text-dark align-self-center">
                          {`${stu?.firstName} ${stu?.lastName} `}
                          <small className="text-muted" />
                        </span>
                      </div>
                    }
                    Right={
                      <div className="znk-table-dropdown dropdown-menu-width-content pr-0">
                        <DropDown
                          direction="bottom"
                          fixedWidth
                          hoverEnabled={false}
                          closeMenuWhenClick
                          options={[
                            {
                              component: (
                                <div className="d-flex flex-row m-0 py-0 px-1">
                                  <div
                                    className="hover-icon"
                                    onClick={() => {
                                      setAttendanceOfStudent(
                                        student[index].studentUserId,
                                        "missed"
                                      );
                                    }}
                                  >
                                    <IconSignPlus color="red" />
                                  </div>
                                  <div
                                    className="hover-icon"
                                    onClick={() => {
                                      setAttendanceOfStudent(
                                        student[index].studentUserId,
                                        "pending"
                                      );
                                    }}
                                  >
                                    <IconSignMinus color="#72809D" />
                                  </div>
                                  <div
                                    className="hover-icon"
                                    onClick={() => {
                                      setAttendanceOfStudent(
                                        student[index].studentUserId,
                                        "attended"
                                      );
                                    }}
                                  >
                                    <IconSignCheck color="#8FC813" />
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        >
                          <div className="hover-icon py-1 px-2">
                            {studentAttendance[stu?.studentUserId] ===
                            "pending" ? (
                              <IconSignMinus color="#72809D" />
                            ) : studentAttendance[stu?.studentUserId] ===
                              "attended" ? (
                              <IconSignCheck color="#8FC813" />
                            ) : (
                              <IconSignPlus color="red" />
                            )}
                          </div>
                        </DropDown>
                      </div>
                    }
                  />
                );
              })}
              {educator && (
                <ListItem
                  showBottomLine={false}
                  showTopLine={false}
                  className="py-3"
                  Left={
                    <div className="d-flex flex-row">
                      <Avatar
                        name={`${educator?.firstName} ${educator?.lastName} `}
                        named={false}
                        uri={null}
                        size="avatar-small mr-1"
                      />
                      <span className="h45 ml-2 text-dark align-self-center">
                        {`${educator?.firstName} ${educator?.lastName} `}
                        <span className="h5 text-gray">{`(${translate(
                          "modal.infolesson.educator"
                        )})`}</span>
                        <small className="text-muted" />
                      </span>
                    </div>
                  }
                  Right={
                    <div className="znk-table-dropdown dropdown-menu-width-content pr-0">
                      <DropDown
                        direction="bottom"
                        fixedWidth
                        hoverEnabled={false}
                        closeMenuWhenClick
                        options={[
                          {
                            component: (
                              <div className="d-flex flex-row m-0 py-0 px-1">
                                <div
                                  className="hover-icon"
                                  onClick={() => {
                                    setEducatorAttendance("missed");
                                  }}
                                >
                                  <IconSignPlus color="red" />
                                </div>
                                <div
                                  className="hover-icon"
                                  onClick={() => {
                                    setEducatorAttendance("pending");
                                  }}
                                >
                                  <IconSignMinus color="#72809D" />
                                </div>
                                <div
                                  className="hover-icon"
                                  onClick={() => {
                                    setEducatorAttendance("attended");
                                  }}
                                >
                                  <IconSignCheck color="#8FC813" />
                                </div>
                              </div>
                            ),
                          },
                        ]}
                      >
                        <div className="hover-icon py-1 px-2">
                          {educatorAttendance === "pending" ? (
                            <IconSignMinus color="#72809D" />
                          ) : educatorAttendance === "attended" ? (
                            <IconSignCheck color="#8FC813" />
                          ) : (
                            <IconSignPlus color="red" />
                          )}
                        </div>
                      </DropDown>
                    </div>
                  }
                />
              )}
              <div className="borderTop" />
            </div>
            {allStudentMissed && form?.cantcomplete ? (
              <div className="w-100">
                <span className='d-flex flex-row justify-content-left h4 mt-4 mb-2'>
                  {translate('modal.sendnotes.lessonNoteMissed')}
                </span>
                <label className="col-12 text-muted mt-2 mb-2 mt-3 pl-0">
                  {translate("modal.sendnotes.reason")}
                </label>
                <textarea
                  onChange={(e) => {
                    setForm({ ...form, lostReason: e.target.value });
                  }}
                  value={form.lostReason}
                  placeholder="Type here"
                  className="znk-input w-100 p-3"
                  style={{ height: "150px" }}
                />
              </div>
            ) : (
              <>
                {!isGroupLesson && (
                  <>
                    <label className="col-12 text-muted mt-2 mb-2 mt-3 pl-0">
                      {translate("modal.sendnotes.didhw")}
                    </label>
                    <SelectOption
                      className="col-12 row inline"
                      name="didhw"
                      showNotSelected={false}
                      onChange={(e) => {
                        setForm({ ...form, didHw: e });
                      }}
                      options={[
                        { id: true, text: translate("modal.sendnotes.yes") },
                        { id: false, text: "No" },
                        { id: null, text: "N/A" },
                      ]}
                      value={form.didHw}
                      StyleOptionClassName={{
                        width: "32%",
                        whiteSpace: "nowrap",
                      }}
                      StyleSelect={{
                        justifyContent: "space-between",
                      }}
                    />
                    <div className="mx-0 my-3 p-0 d-flex flex-row">
                      <div className="w-100 d-flex flex-column">
                        <span className="p-0 text-gray">
                          {translate("modal.sendnotes.score")}
                        </span>
                        <Input
                          disabled={form.didHw === null || form.didHw === false}
                          type="string"
                          name="scoreAlphanumeric"
                          placeholder={translate("modal.sendnotes.score")}
                          value={form.scoreAlphanumeric}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              scoreAlphanumeric: e.target.value,
                            });
                          }}
                        />
                        {charsScoreMax <
                          (form?.scoreAlphanumeric?.length || 0) && (
                          <span className="h5 text-error">
                            {`${translate("modal.sendnotes.charsMax", {
                              num:
                                (form?.scoreAlphanumeric?.length || 0) -
                                charsScoreMax,
                            })}`}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="borderTop my-3" />
                  </>
                )}
                <label className="col-12 text-muted mt-2 mb-2 mt-3 pl-0">
                  {translate("modal.sendnotes.nexthw")}
                </label>
                <SelectOption
                  className="col-12 row inline"
                  name="newHw"
                  showNotSelected={false}
                  onChange={(e) => {
                    setForm({ ...form, newHw: e });
                  }}
                  options={[
                    { id: true, text: translate("modal.sendnotes.yes") },
                    { id: false, text: "No" },
                  ]}
                  value={form.newHw}
                  StyleOptionClassName={{
                    width: "49%",
                    whiteSpace: "nowrap",
                  }}
                  StyleSelect={{
                    justifyContent: "space-between",
                  }}
                />
                <div className="mx-0 my-3 p-0 d-flex flex-row">
                  <div className="w-100 d-flex flex-column">
                    <span className="p-0 text-gray">
                      {translate("modal.sendnotes.titlelabel")}
                    </span>
                    <Input
                      disabled={form.newHw === false}
                      type="text"
                      name="title"
                      value={form.descriptionHw}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          descriptionHw: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="mx-0 my-3 p-0 d-flex flex-row">
                  <div className="w-100 d-flex flex-column">
                    <span className="p-0 text-gray">
                      {translate("modal.sendnotes.duedate")}
                    </span>
                    <Input
                      disabled={form.newHw === false}
                      type="date"
                      name="duedate"
                      value={form.dueDateHw}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          dueDateHw: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="borderTop my-3" />
                <div className="w-100">
                  <label className="col-12 text-muted mt-2 mb-2 mt-3 pl-0">
                    {translate("modal.sendnotes.acqknowledge")}
                  </label>
                  <textarea
                    onChange={(e) => {
                      setForm({ ...form, acqKnowledge: e.target.value });
                    }}
                    value={form.acqKnowledge}
                    placeholder={translate("modal.sendnotes.typehere")}
                    className="znk-input w-100 p-3"
                    style={{ height: "150px" }}
                  />
                  {charsAcqMin > (form?.acqKnowledge?.length || 0) && (
                    <span className="h5 text-error">
                      {`${translate("modal.sendnotes.charsLeft", {
                        num: charsAcqMin - (form?.acqKnowledge?.length || 0),
                      })}`}
                    </span>
                  )}
                </div>
                {!isGroupLesson && (
                  <div className="w-100">
                    <label className="col-12 text-muted mt-2 mb-2 mt-3 pl-0">
                      {translate("modal.sendnotes.studenteffort")}
                    </label>
                    <textarea
                      onChange={(e) => {
                        setForm({ ...form, studentEffort: e.target.value });
                      }}
                      value={form.studentEffort}
                      placeholder={translate("modal.sendnotes.typehere")}
                      className="znk-input w-100 p-3"
                      style={{ height: "150px" }}
                    />
                    {charsEffortMin > (form?.studentEffort?.length || 0) && (
                      <span className="h5 text-error">
                        {`${translate("modal.sendnotes.charsLeft", {
                          num:
                            charsEffortMin - (form?.studentEffort?.length || 0),
                        })}`}
                      </span>
                    )}
                  </div>
                )}
                <div className="w-100">
                  <label className="col-12 text-muted mt-2 mb-2 mt-3 pl-0">
                    {translate("modal.sendnotes.othercomments")}
                  </label>
                  <textarea
                    onChange={(e) => {
                      setForm({ ...form, others: e.target.value });
                    }}
                    value={form.others}
                    placeholder={translate("modal.sendnotes.typehere")}
                    className="znk-input w-100 p-3"
                    style={{ height: "150px" }}
                  />
                  <span className="h5 text-error">
                    {translate("modal.sendnotes.optional")}
                  </span>
                </div>
              </>
            )}
            {!form?.cantcomplete && (
              <Button
                disabled={false}
                activity={loadMutation}
                onClick={() => {
                  sendLessonNotesSubmit(
                    parseDataToMutationLessonNotes(
                      form,
                      dataLesson,
                      studentAttendance,
                      "draft",
                      isGroupLesson,
                      allStudentMissed
                    )
                  );
                }}
                className="w-100 mt-4 mb-2"
                color="input"
                size="small"
                label={
                  <div className="d-flex flex-row justify-content-between align-items-center m-0">
                    <span className="h45" style={{ color: "#7079FF" }}>
                      {translate("modal.sendnotes.saveindraft")}
                    </span>
                    <IconArrowRight size={15} color="#7079FF" />
                  </div>
                }
              />
            )}
          </FormGroup>
          <StickyButton
            disabled={!validation}
            onClickButtonFunction={() => {
              sendLessonNotesSubmit(
                parseDataToMutationLessonNotes(
                  form,
                  dataLesson,
                  studentAttendance,
                  "sent",
                  isGroupLesson,
                  allStudentMissed
                )
              );
            }}
            activity={loadMutation}
            color="#696CFF"
            className="w-100 mb-6"
            size="large"
            label={
              <div className="d-flex flex-row justify-content-between">
                <span>
                  {form.cantcomplete
                    ? translate("modal.sendnotes.endlesson")
                    : translate("modal.sendnotes.sendnotes")}
                </span>
              </div>
            }
          />
        </div>
      </Modal>
    </>
  );
}

export default SendLessonNotes
