import React, { useMemo, useState } from 'react'
import { IconLesson, ToolTipInfo } from '@Knowledge-OTP/znk-ui-components'
import PlanListItem from './PlanListItem'
import CardHeader from '../Components/CardHeader'
import useStudentsCheckIn from './Model/useStudentsCheckIn'
import useStudentsMissingHomeworks from './Model/useStudentsMissingHomeworks'
import useStudentsPendingLessons from './Model/useStudentsPendingLessons'
import useStudentsMissingLessons from './Model/useStudentsMissingLessons'
import CheckInStudentModal from './Modal/CheckInStudent'
import DismissNotification from './Modal/DismissNotification'
import SkeletonListItem from './PlanListItem/Skeleton'
import { TabContent, TabPane } from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment'
import { CheckInTypeInt } from '../../../../Plans/modal/scheduled/CheckIns'
//import { planQueryResult } from '../TestDates/Model/QueryResults/planQueryResult'

const IssueCheckIn = (props) => {
    const typeEnum = {
        planCheckIn: 'checkinPlans',
        missingHomeworks: 'homework',
        pendingLessons: 'pendingLessons',
        missingLessons: 'missingLessons',
        general: 'general'
    }
    const {type = typeEnum.planCheckIn} = props
    const { data: dataCheckIn, loading: checkinPlansLoading, refetch: refetchCheckIn } = useStudentsCheckIn({})
    const { data: dataHomework, loading: homeworkLoading, refetch: refetchHomework } = useStudentsMissingHomeworks({})
    const { data: dataPending, loading: pendingLessonsLoading, refetch: refetchPending } = useStudentsPendingLessons({})
    const { data: dataMissing, loading: missingLessonsLoading, refetch: refetchMissing } = useStudentsMissingLessons({})
    const [fakeLoading, setFakeLoading] = useState(false)
    const allNotifications = useMemo(() => {
        const notifications = {};
    
        if (dataCheckIn?.plansCheckIns) {
            notifications.checkinPlans = dataCheckIn.plansCheckIns.checkinPlans || [];
        }
        if (dataHomework?.studentsHomeworks) {
            notifications.homework = dataHomework.studentsHomeworks.homework || [];
        }
    
        if (dataPending?.studentsPendingLessons) {
            notifications.pendingLessons = dataPending.studentsPendingLessons.pendingLessons || [];
        }
    
        if (dataMissing?.studentsMissingLessons) {
            notifications.missingLessons = dataMissing.studentsMissingLessons.missingLessons || [];
        }
    
        return notifications;
    }, [dataCheckIn, dataHomework, dataPending, dataMissing]);
    
    
    
    const {homework, missingLessons, pendingLessons, checkinPlans, general} = allNotifications
    const lengthNotifications = Array.isArray(allNotifications[type])
    ? allNotifications[type].length
    : 0;
  
  
    const { translate } = useTranslation()
    const titleEnum = {
        checkinPlans: translate("dashboard.issueCheckIn.checkInPlansTitle"),
        homework: translate("dashboard.issueCheckIn.homeworkTitle"),
        pendingLessons: translate("dashboard.issueCheckIn.pendingLessonsTitle"),
        missingLessons: translate("dashboard.issueCheckIn.missingLessonsTitle"),
    }
    const infoEnum = {
        checkinPlans: translate("dashboard.issueCheckIn.checkInPlansInfo"),
        homework: translate("dashboard.issueCheckIn.homeworkInfo"),
        pendingLessons: translate("dashboard.issueCheckIn.pendingLessonsInfo"),
        missingLessons: translate("dashboard.issueCheckIn.missingLessonsInfo"),
    }

    const refetch = () => {
        setFakeLoading(true)
        if (type === typeEnum.planCheckIn) refetchCheckIn().then(
            () => { setFakeLoading(false) },
            () => { setFakeLoading(false) }
        )
        if (type === typeEnum.missingHomeworks) refetchHomework().then(
            () => { setFakeLoading(false) },
            () => { setFakeLoading(false) }
        )
        if (type === typeEnum.pendingLessons) refetchPending().then(
            () => { setFakeLoading(false) },
            () => { setFakeLoading(false) }
        )
        if (type === typeEnum.missingLessons) refetchMissing().then(
            () => { setFakeLoading(false) },
            () => { setFakeLoading(false) }
        )

    }
    const loading = useMemo(() => checkinPlansLoading || fakeLoading || pendingLessonsLoading || homeworkLoading || missingLessonsLoading, 
    [checkinPlansLoading, homeworkLoading, fakeLoading, pendingLessonsLoading, missingLessonsLoading])
    
    const getTooltipText = (tasksForWidget = []) => {
        return tasksForWidget?.map(
            (task) => {
                return <span>{task?.title} <br/> 
                <small>{moment(task?.endDate).format('YYYY-MM-DD HH:mm')} <br/></small>
                <small>{task?.educator?.firstName} {task?.educator?.lastName} <br/></small>
                <small>********** <br/></small>
               </span>
            }
        )
    }

    return (
        <div className={`column w-100`}>
            <CardHeader
                innerInfo={infoEnum[type]}
                titleModalInfo={translate("dashboard.issueCheckIn.infoTitleModal")}
                title={titleEnum[type]}
                className={`secondaryBackground`}
                onReload={() => { refetch() }}
                loading={loading}
                type={type}
            />
            <div className='d-flex flex-column w-100 m-0 p-0'
            // style={{backgroundColor: '#f8f9fa'}}
            >
                <TabContent activeTab={loading ? 'loading' : lengthNotifications > 0 ? type : 'blank'}>
                    <TabPane tabId={'loading'}>
                        <div className='d-flex flex-column px-5'>
                            {
                                new Array(10).fill(0).map(() => {
                                    return (
                                        <SkeletonListItem className={`mb-2`} />
                                    )
                                })
                            }
                        </div>
                    </TabPane>
                    <TabPane tabId={typeEnum.planCheckIn}>
                        <>
                            {
                                (checkinPlans || []).map(
                                    (student, index) => {
                                        const textToShowOnTooltip = <>
                                            <span>{student.planName}</span> <br/>
                                            <small>{`${student.firstName} ${student.lastName}`}</small> <br/>
                                            <small>{moment(student.checkInDate?.date).format('YYYY-MM-DD HH:mm')} <br/></small>
                                        </>
                                        return (
                                            <ToolTipInfo
                                                tooltipId={`checkin-plan-${index}`}
                                                disable={false}
                                                innerElement={
                                                    <div>
                                                        {textToShowOnTooltip}
                                                    </div>
                                                }
                                                place='bottom'
                                            >
                                            <PlanListItem
                                                refetch={refetch}
                                                studentName={`${student?.firstName} ${student?.lastName}`}
                                                studentId={student?.id || student['_id']}
                                                text={`Check In Plan - ${translate(CheckInTypeInt[student?.checkInDate?.type])}`}
                                                reason={student?.checkInDate?.type.toString() !== 'regular' ? student?.checkInDate?.type : 'checkinPlan' }
                                                description={translate("dashboard.issueCheckIn.checkinPlan")}
                                                studentData={student}
                                            />
                                            </ToolTipInfo>
                                        )
                                    }
                                )
                            }
                        </>
                    </TabPane>
                    <TabPane tabId={typeEnum.missingHomeworks}>
                        <>
                             {
                                (homework || []).map(
                                    (student, index) => {
                                        const textToShowOnTooltip = getTooltipText(student?.tasksForWidget)
                                        return (
                                            <ToolTipInfo
                                            tooltipId={`homework-${index}`}
                                            disable={false}
                                            innerElement={
                                                <div>
                                                    {textToShowOnTooltip}
                                                </div>
                                            }
                                            place='bottom'
                                         >
                                            <PlanListItem
                                                refetch={refetch}
                                                studentName={`${student?.firstName} ${student?.lastName}`}
                                                studentId={student?.id}
                                                text={'Missing Homework'}
                                                reason={"homework"}
                                                description={translate("dashboard.issueCheckIn.homework")}
                                                studentData={student}
                                            />
                                             </ToolTipInfo>
                                        )
                                    }
                                )
                            }

                        </>
                    </TabPane>
                    <TabPane tabId={typeEnum.missingLessons}>
                        <>
                            {
                                (missingLessons || []).map(
                                    (student, index) => {
                                        const textToShowOnTooltip = getTooltipText(student?.tasksForWidget)
                                        const textMissingLessons = student?.tasksForWidget[0]?.service?.name + ' ' + student?.tasksForWidget[0]?.topic?.name
                                        return (
                                            <ToolTipInfo
                                            tooltipId={`missing-${index}`}
                                            disable={false}
                                            innerElement={
                                                <div>
                                                    {textToShowOnTooltip}
                                                </div>
                                            }
                                            place='bottom'
                                            >
                                            <PlanListItem
                                                refetch={refetch}
                                                studentName={`${student?.firstName} ${student?.lastName}`}
                                                studentId={student?.id}
                                                text={textMissingLessons}
                                                reason={"missing"}
                                                description={translate("dashboard.issueCheckIn.missing")}
                                                studentData={student}
                                            />
                                            </ToolTipInfo>
                                        )
                                    }
                                )
                            }
                       
                        </>
                    </TabPane>
                    <TabPane tabId={typeEnum.pendingLessons}>
                        <>

                            {
                                (pendingLessons || []).map(
                                    (student, index) => {
                                        const textToShowOnTooltip = getTooltipText(student?.tasksForWidget)
                                        return (
                                            <ToolTipInfo
                                            tooltipId={`pending-${index}`}
                                            disable={false}
                                            innerElement={
                                                <div>
                                                    {textToShowOnTooltip}
                                                </div>
                                            }
                                            place='bottom'
                                            >
                                            <PlanListItem
                                                refetch={refetch}
                                                studentName={`${student?.firstName} ${student?.lastName}`}
                                                studentId={student?.id || student['_id']}
                                                text={'Pending Lessons'}
                                                reason={"pending"}
                                                description={translate("dashboard.issueCheckIn.pending")}
                                                studentData={student}
                                            />
                                            </ToolTipInfo>
                                        )
                                    }
                                )
                            } 
                        </>
                    </TabPane>
                    <TabPane tabId={typeEnum.general}>
                        <>

                            {
                                (general || []).map(
                                    (student, index) => {
                                        const textToShowOnTooltip = getTooltipText(student?.tasksForWidget)
                                        return (
                                            <ToolTipInfo
                                            tooltipId={`general-${index}`}
                                            disable={false}
                                            innerElement={
                                                <div>
                                                    {textToShowOnTooltip}
                                                </div>
                                            }
                                            place='bottom'
                                            >
                                            <PlanListItem
                                                refetch={refetch}
                                                studentName={`${student?.firstName} ${student?.lastName}`}
                                                studentId={student?.id || student['_id']}
                                                text={'General'}
                                                reason={"general"}
                                                description={translate("dashboard.issueCheckIn.general")}
                                                studentData={student}
                                            />
                                            </ToolTipInfo>
                                        )
                                    }
                                )
                            } 
                        </>
                    </TabPane>
                    <TabPane tabId={'blank'}>
                        <div className={`d-flex flex-row justify-content-center w-100`} style={{ height: '200px' }}>
                            <div className={`d-flex flex-column justify-content-center align-items-center text-center`}>
                                <IconLesson size={40} className={`text-disabled`} />
                                <label className={`h45 mt-4 text-gray`}>There are no notifications</label>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
            <CheckInStudentModal />
            <DismissNotification />
        </div>
    )
}

export default IssueCheckIn