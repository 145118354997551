import {useLazyQuery, gql} from '@apollo/client'

const useGetUniversity = () => {
  return useLazyQuery(
    gql`query($universityId: ID!) {
        getUniversity(universityId: $universityId) {
            id
            name
            shortDescription
            collegeUnitId
            ranking
            stateAbbr
            city
            typeCategory
            typeYear
            undergraduateSize
            fourYearGraduationRate
            acceptanceRate
            satAverage
            actCumulativeMidpoint
            rankingsBestValue
            rankingsBestTotal
            avgCostOfAttendance 
            logo
        }
    }`,
  )
}

export default useGetUniversity
