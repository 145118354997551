import React, { useEffect } from 'react'
import { 
         ToolTipInfo,
         ResumenInvoice,
         IconStarFilled,
         IconLesson } from '@Knowledge-OTP/znk-ui-components'

import { gql, useLazyQuery } from '@apollo/client'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import omit from 'lodash/omit'
import usePaymentScheduledReducer from '../../../../../reducers/payment-scheduled'
import useGetPaymentMethod from '../../../../CounselingPlans/Modal/viewPlanModal/Tabs/Payments/Components/GetPaymentMethod/useGetPaymentMethod'
import convertCamelToSentence from '../../Utils/camelToSentence'
import useViewInvoiceWithPymtItem from '../../../../Payments/Invoices/viewInvoiceWithPaymentItem/useViewInvoiceWithPymtItem'
import ViewInvoiceWithPaymentItem from '../../../../Payments/Invoices/viewInvoiceWithPaymentItem'
import Big from 'big.js'

const PaymentStatus = {
  pendingPayment: 'pendingPayment',
  unPaid: 'unPaid',
  paid: 'paid',
  canceled: 'canceled'
}

const Payments = (props) => {
  const { translate } = useTranslation()
  const { info, isCounseling = false } = props
  const {formatNumber} = usePaymentScheduledReducer()
  const {open: openViewInvoice} = useViewInvoiceWithPymtItem()
  let approvalFeePayment = {}

  const {label: paymentMethod} = useGetPaymentMethod(info?.studentUser?.studentUserId)

  const [getPaymentItems, { data: paymentsQuery }] = useLazyQuery(
    gql`
      query($planId: String!) {
        myPaymentsItems(planId: $planId) {
          docs {
            id
            amount
            status
            description
            category
            type
            date
          }
        }
      }
    `
  )
  useEffect(() => {
    if (info.id) {
      getPaymentItems({
        variables: { planId: info.id }
      })
    }
  }, [info, getPaymentItems])

  // let pendingTotal = 0
  // let paidTotal = 0
  let total = 0
  if (paymentsQuery && paymentsQuery.myPaymentsItems && paymentsQuery.myPaymentsItems.docs) {
    total = paymentsQuery.myPaymentsItems.docs
    .filter(data => !['canceled', 'draft'].includes(data.status))
    .reduce((acc, data) => acc.plus(new Big(data.amount)), new Big(0))  
    //total = paymentsQuery.myPaymentsItems.docs.reduce((acc, data) => {
      // if (data.status === PaymentStatus.pendingPayment || data.status === PaymentStatus.unPaid) {
      //   pendingTotal += new Big(data.amount)
      // }
      // if (data.status === PaymentStatus.paid) {
      //   paidTotal += new Big(data.amount)
      // }
      //return acc.plus(new Big(data.amount))
    //}/, new Big(0))
  }
  const summary = {
    borderRadius: 12,
    fontSize: 14,
    backgroundColor: '#17A1E6',
    minHeight: 121,
    width: '100%',
    color: '#fff'
  }
  let numPayments = 0
  let atSigningId = ''
  const paymentItems = paymentsQuery && paymentsQuery.myPaymentsItems && paymentsQuery.myPaymentsItems.docs ? paymentsQuery.myPaymentsItems.docs.map(p => omit(p, ['__typename'])).sort(
    (a, b) =>
      new Date(a.date).getTime() - new Date(b.date).getTime()
  ).map(p => {
    const newP = { ...p }
    if (newP.type === 'otherFixed') atSigningId = newP.id
    if (
      !['otherFixed', 'universityCharge'].includes(p.type) && 
      [PaymentStatus.paid, PaymentStatus.unPaid, PaymentStatus.pendingPayment].includes(p?.status)
    ) {
      newP.num = numPayments + 1
      numPayments += 1
    }
    return newP
  }) : []

  let approvalFeeStatus = PaymentStatus.paid
  const approvalFee = paymentItems.reduce((amount, payment) => {
    if (payment.type === 'otherFixed') {
      approvalFeePayment = payment
      if (payment.status !== PaymentStatus.paid) {
        const unPaid =  moment(payment?.date).utc().endOf('month') < moment()
        approvalFeeStatus = unPaid === true ? PaymentStatus.unPaid : PaymentStatus.pendingPayment
      }
      return amount + payment.amount
    }
    return amount
  }, 0)
  const counselingStyle = isCounseling ? {
    borderRadius: '16px',
    backgroundColor: '#35425b',
    color: '#ffffff'
  } : {} 



  const RenderPaymentItem = (props) => {
    const {data, index, tooltipId='tooltipId'} = props
    const unPaid = data?.status === PaymentStatus.pendingPayment && moment(data.date).utc().endOf('month') < moment()
    return (
      <ToolTipInfo
      key={`${tooltipId}-${index}`}
      tooltipId={`${tooltipId}-${index}`}
      innerElement={<span>{convertCamelToSentence(data?.status)}</span>}
      >
      <ResumenInvoice
        type={unPaid === true ? 'unPaid' : data?.status}
        text={`${translate('student.modal.payments.endOf')} ${moment(data?.date).utc().endOf('month').format('MMMM YYYY')}`
        }
        className='mx-0 my-1 px-2 py-1'
        amount={data?.amount}
        icon={
          data?.category === 'counseling'
            ? <IconStarFilled size={15} className='text-muted' color={'#2C3F58'}/>
            : <IconLesson size={15} className='text-muted' />
          }
        onClick={() => 
          { 
            openViewInvoice(data?.id) 
          }
      }
      />
    </ToolTipInfo>
    )
  }

  return (
    <>
      <div className='d-flex flex-column'>
        <div style={{...summary, ...counselingStyle}} className='px-4 py-4 pb-3 mt-4'>
          <div className='d-flex justify-content-center font-weight-bold'>
            <div className='w-50'>
              {translate('program.plan.totalPrice')} <br />
              <span className='font-weight-light'>USD</span>
            </div>
            <div className='h1 w-50 text-white font-weight-bold text-right'>${formatNumber(Number(total))}</div>
          </div>
          <div className='border-top w-100 border-transparent mt-3 d-flex justify-content-center font-weight-normal text-transparent'>
            <div className='w-50 pt-4'>
              {translate('program.plan.paymentMethod')}
            </div>
            <div className='w-50 pt-4 text-right text-capitalize'>
              {paymentMethod}
            </div>
          </div>
        </div>
      </div>
      <div className='px-0 pt-4 pb-3'>
        <span className='h4 font-weight-bold text-title'>{translate('program.plan.paymentHistory')}</span>
      </div>
      <div className='borderTop forceOverflow' />
      {approvalFee && approvalFeePayment ? (
        <ToolTipInfo
        key={`${approvalFee}`}
        tooltipId={`other${approvalFee}`}
        innerElement={<span>{convertCamelToSentence(approvalFeeStatus)}</span>}
      >
        <ResumenInvoice
          type={approvalFeeStatus}
          text={
            approvalFeePayment?.category === 'counseling'
              ? `At Approval`
              : translate('program.plan.infoModal.payment.approvalFee')
          }
          className='mx-0 my-1 px-2 py-1'
          amount={approvalFee}
          icon={
            approvalFeePayment?.category === 'counseling'
              ? <IconStarFilled size={15} className='text-muted' color={'#2C3F58'}/>
              : <IconLesson size={15} className='text-muted' />
          }
          onClick={() => {
            openViewInvoice(atSigningId)}}
        />
      </ToolTipInfo>
      ) : null}
      {paymentItems.filter(
        item => item.type !== 'otherFixed' && [PaymentStatus.paid, PaymentStatus.unPaid].includes(item.status) 
        ).map((data, index) => {
        return ( 
          <RenderPaymentItem data={data} index={index} tooltipId={'history'} />  
        )
      }
      )}
      <div className='px-0 pt-4 pb-3'>
        <span className='h4 font-weight-bold text-title'>{translate('program.plan.upcommingPayments')}</span>
      </div>
      <div className='borderTop forceOverflow' />
      {paymentItems.filter(item => !['otherFixed'].includes(item.type) && item.status === PaymentStatus.pendingPayment).map(
        (data, index) => { 
          return (<RenderPaymentItem data={data} index={index} tooltipId={'other'} />)
        }
      )}
    <ViewInvoiceWithPaymentItem />
    </>
  )
}

export default Payments
