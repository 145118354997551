import React, {useRef, useEffect} from 'react'
import { SelectOption } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'

const RenderLocationInputs = (props) => {
    const {setForm = () => {}, form = {}, educatorLink = null} = props
    const {translate} = useTranslation()

    const linkRef = useRef(null)
    useEffect(() => {
        if (linkRef?.current) linkRef.current.focus()
    }, [form.locationLessonLink])

    useEffect(() => {
        if(form.locationLesson === 'hangouts') 
            setForm({...form, locationLessonLink: educatorLink})
      //eslint-disable-next-line
    }, [form.locationLesson])
    
    return (
      <>
        <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
          {translate('modal.newplan.location')}
        </label>
        <SelectOption
          name='locationLesson'
          showNotSelected
          className='col-12'
          onChange={(e) => { setForm({ ...form, locationLesson: e }) }}
          changeFunction={() => {
            setForm({ ...form, locationLesson: null })
          }}
          changeLabel={translate('modal.newplan.changelocation')}
          options={[
            { id: 'virtualClassroom', text: 'Virtual Classroom' },
            { id: 'zinkerzPlatform', text: translate('modal.newplan.plandetails.zinkerzp') },
            { id: 'hangouts', text: 'Meets' },
            { id: 'zoom', text: 'Zoom' },
            { id: 'inPerson', text: translate('modal.newplan.plandetails.inperson') }
          ]}
          value={form.locationLesson}
        />
        {['inPerson', 'hangouts', 'zoom'].indexOf(form.locationLesson) !== -1 && (
          <>
            <label className='col-12 text-muted mt-2 pl-0'>
              {form.locationLesson === 'inPerson' ? translate('modal.newplan.address') : translate('modal.newplan.locationlink')}
            </label>
            <input
              ref={linkRef}
              className='znk-input form-control'
              name='locationPlanLink'
              onChange={(e) => {
                setForm({ ...form, locationLessonLink: e.target.value })
              }}
              placeholder={form.locationLesson === 'inPerson' ? translate('modal.newplan.address') : 'http://zoom.com/ASDF'}
              value={form.locationLessonLink}
              disabled={form.locationLesson === 'hangouts'}
            />
          </>
        )}
      </>
    )
  }


  export default RenderLocationInputs