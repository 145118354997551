import React from 'react'
import {
  Field,
  SelectOption,
  PlainTextInput,
  InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import NewActivity from '../../../../../modal/newPlan/Components/NewActivity'
import useTranslation from '../../../../../../../i18n/useTranslation'
import {TabContent, TabPane} from 'reactstrap'
import escapeRegExp from 'lodash/escapeRegExp'

const PlanDetails = (props) => {
  const { data, form, setForm, isEdit = false, disabled = false } = props
  const { translate } = useTranslation()
  // Reset testDate if it doesn't match with current serviceId
  if (form.testdate && form.testdate.serviceId !== form.idservice && !isEdit) {
    setForm(pre => ({ ...pre, testdate: null }))
  }
  const onChange = (value) => {
    if (form.typePlan === 'date') {
      setForm(pre => ({
        ...pre,
        planname: '',
        idservice: value
      }))
    } else {
      setForm(pre => ({
        ...pre,
        planname: `${data.docs.filter(item => item.id === value)[0].name} Plan `,
        idservice: value
      }))
    }
  }
  return (
    <>
      <div className='py-6'>
        <h3><b>{translate('modal.newplan.plandetails')}</b></h3>
        <label className='col-12 text-gray font-weight-light mb-2 mt-3 pl-0'>
          {translate('modal.newplan.plandetails.service')}
        </label>
        <Field
          className='w-100'
          type={InputSelect}
          fieldName='idservice'
          placeholder={translate('modal.newplan.selectservice')}
          options={data && data.docs && [{ value: '0', label: translate('modal.newplan.selectservice') }].concat(data.docs.map((serv) => ({ value: serv.id, label: serv.name })))}
          InputClassName='w-100'
          onChange={onChange}
          filterOption={(obj, string) => {
            if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
            return false
          }}
          isDisabled={disabled}
        />
        {
          form.typePlan !== 'time' &&
          <>
            <label className='col-12 text-muted mb-2 mt-5 pl-0'>
              {translate('modal.newplan.testdate')}
            </label>
            {form.idservice && form.idservice !== '0'
              ? <NewActivity
                statusPlan={form.statusPlan}
                placeholder={translate('modal.newplan.testdate')}
                fieldName='testdate'
                setTestDate={(testdate) => {
                  setForm({
                    ...form,
                    planname: (form.planname === undefined || form.planname === '')
                      ? testdate.name.replace(' ', ' - ') : form.planname,
                    testdate: {
                      date: testdate.startDate,
                      name: testdate.name,
                      serviceId: testdate.serviceId
                    }
                  })
                }}
                idService={form.idservice}
                nameService={form.idservice ? (data?.docs || []).filter((serv) => serv.id === form.idservice)[0]?.name : ''}
              >
                <TabContent activeTab={form?.testdate ? 'selected' : 'none'}>
                  <TabPane tabId={'selected'}>
                    <label className='py-1 btn-link'>
                      {form?.testdate?.name}
                    </label>
                  </TabPane>
                  <TabPane tabId={'none'}>
                    <label className='py-1 btn-link'>
                      {translate('modal.newplan.plandetails.selecttestdate')}
                    </label>
                  </TabPane>
                </TabContent>

              </NewActivity>
              : <label className='text-muted'>{translate('modal.newplan.selectservice')}</label>}
          </>
        }
        <label className='col-12 text-gray font-weight-light mt-2 mb-2 mt-3 pl-0'>
          {translate('modal.newplan.studyplanname')}
        </label>
        <Field
          className='col-12'
          type={PlainTextInput}
          fieldName='planname'
          placeholder={translate('modal.newplan.enterplanname')}
          disabled={disabled}
        />
        <label className='col-12 text-gray font-weight-light mt-2 mb-2 mt-3 pl-0'>
          {translate('modal.newplan.location')}
        </label>
        <Field
          className='col-12'
          type={SelectOption}
          fieldName='locationPlan'
          showNotSelected
          changeFunction={() => {
            setForm({ ...form, locationPlan: null })
          }}
          changeLabel={translate('modal.newplan.changelocation')}
          options={[
            { id: 'virtualClassroom', text: 'Virtual Classroom' },
            { id: 'zinkerzPlatform', text: translate('modal.newplan.plandetails.zinkerzp') },
            { id: 'hangouts', text: 'Meets' },
            { id: 'zoom', text: 'Zoom' },
            { id: 'inPerson', text: translate('modal.newplan.plandetails.inperson') }
          ]}
          isDisabled={disabled}
        />
        {form.locationPlan === 'inPerson' && (
          <>
            <label className='col-12 text-gray font-weight-light mt-2 pl-0'>
              {form.locationPlan === 'inPerson' ? translate('modal.newplan.address') : translate('modal.newplan.locationlink')}
            </label>
            <Field
              className='col-12'
              type={PlainTextInput}
              fieldName='locationPlanLink'
              placeholder={form.locationPlan === 'inPerson' ? translate('modal.newplan.address') : 'http://zoom.com/ASDF'}
              disabled={disabled}
            />
          </>
        )}
      </div>
      {/* <div className='borderTop my-2' /> */}
    </>
  )
}
export default PlanDetails
