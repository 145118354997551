import { gql, useQuery } from '@apollo/client'

export default (vars) => {
  return useQuery(
    gql`
    query($id: String!) {
        user(id: $id) {
            id
            avatar
            emails {
              address
              verified
            }
            firstName
            lastName
            studentInfo {
              studentType
              parents {
                parentUserId
                phone
                managePayments
                manageApprovals
                firstName
                lastName
                email
              }
              universities {
                  id
                  collegeId
                  name
                  status
                  early
                  logo
                  attending
                  isPrincipal
              }
              graduationDate
            }
            country {
              name
            }
        }
      }`,
    {
      variables: vars
    }
  )
}
