import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {IconLoad } from '@Knowledge-OTP/znk-ui-components'
import {
  parsePlanToEdit,
  redirectToSendAllPlan,
  redirectToSendOnePlan,
  openNewPlanModal,
  openNewPlanIndividualModal,
  redirectToCancelOrPause,
  redirectToResumePlan,
  openPlanModal
  , openEditChildPlan,
  viewGroupPlan
} from './utils.js'
import useTranslation from '../../../i18n/useTranslation'
import useCompletePlanModal from '../modal/CompletePlan/useCompletePlan'
import useReviewScheduledModal from '../GroupPlans/modal/ReviewScheduled/useReviewScheduledModal'
import useGetGroupPlan from '../Model/useGetGroupPlan'
import { parseLpwGroupPlan } from '../GroupPlans/modal/Edit/util'
import {showAlert} from '../../../common/Alert/util'
import useRemoveGroupLessonByDates from '../Model/useRemoveGroupLessonByDates.js'
import useMoveStudentModal from '../GroupPlans/modal/MoveStudent/useMoveStudentModal.js'
import moment from 'moment'
import useCompleteGroupPlanModal from '../GroupPlans/modal/CompleteGroupPlan/useCompleteGroupPlanModal.js'
import useDeleteGroupPlanModal from '../GroupPlans/modal/DeleteGroupPlan/useDeleteGroupPlanModal.js'
const TableSelectorField = (item, extraConfig, refetch) => {
  const localTime = useSelector((state) => state.timezone.zone)
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const {open: openCompletePlanModal, close: closeCompletePlanModal} = useCompletePlanModal()
  const {open: openDeletePlanModal, close: closeDeletePlanModal} = useDeleteGroupPlanModal()
  let options
  const mainBtnConfig = {}

  const {open: openCompleteGroupPlan} = useCompleteGroupPlanModal()
  const {open: openReviewScheduledModal, close: closeReviewScheduledModal, reviewProps, setLoading : setLoadingReviewModal} = useReviewScheduledModal()

  const [getGroupPlan, {loading: loadGroupPlanData}] = useGetGroupPlan()
  const [removeGroupLessons] = useRemoveGroupLessonByDates(
    () => {
      //cerrar modal review scheduled
      setLoadingReviewModal(false)
      closeReviewScheduledModal()
      showAlert({
        text: `Success!`,
        status: 'success'
      }, dispatch)
      // setLoading(false)
    },
    (err) => {
      // setLoading(false)
      setLoadingReviewModal(false)
      showAlert({
        text: err.toString(),
        status: 'error'
      }, dispatch)
    }
  )


  const {open: openMoveStudenModal, close: closeMoveStudentModal} = useMoveStudentModal()
  
  const editPlan = (item, isDuplicate = false) => {
    if(item?.groupId) {
      openEditChildPlan(item, dispatch)
    } else {
      let parseItem = parsePlanToEdit(item, extraConfig.thisService.name) 
      if (isDuplicate) {
        parseItem.comments = ''
        parseItem = {
          ...parseItem,
          isDuplicate,
          isFirstPlan: false,
          student: isDuplicate ? null : parseItem?.student
        }
      }
      openNewPlanIndividualModal(parseItem, dispatch)
    }
  }
  
  switch (item.status) {
    case 'draft':
      if (item.kind !== 'group') {
        mainBtnConfig.text = translate('plans.actions.edit')
        mainBtnConfig.onClick = () => { 
          item.groupId ? 
            openEditChildPlan(item, dispatch) : 
            openNewPlanIndividualModal(
              parsePlanToEdit(item, extraConfig.thisService.name), dispatch
            ) 
          }
        options = [
          {
            component: <span onClick={() => { 
              editPlan(item, false)
            }}>{translate('plans.actions.edit')}</span>
          },
          {
            component: <span 
              className={item?.groupId ? `text-gray hover-disabled` : ``}
              onClick={() => { 
                if(!item?.groupId) editPlan(item, true)
              }
              }>Duplicate plan</span>
          },
          {
            component: <span onClick={() => { openPlanModal(item, extraConfig, dispatch) }}>{translate('plans.actions.view')}</span>
          },
          {
            component: <span onClick={() => { redirectToSendOnePlan(item, refetch, dispatch) }}>{translate('plans.actions.send')}</span>
          },
          {
            component: <span onClick={
              () => { 
                openDeletePlanModal(() => {
                  refetch()
                  closeDeletePlanModal()
                }, item, true /**useMutation**/)
              }
            }>{translate('plans.actions.cancel')}</span>
          },
          {
            component: <span onClick={() => { window.open(`${process.env.LIVEPREP_URL || 'http://dev.liveprep.zinkerz.com'}/onboarding/${item.id}?isAdmin=true`, '_blank') }}>{translate('plans.actions.onboarding')}</span>
          }
        ]
      } else {
        mainBtnConfig.text = translate('plans.actions.edit')
          mainBtnConfig.onClick = () => openNewPlanModal(item, dispatch)

          options = [
            {
              component: <span onClick={() => { redirectToSendAllPlan(item, refetch, dispatch) }}>{translate('plans.actions.sendAll')}</span>
            },
          ]

        options = (options || []).concat([
          {
            component: <span onClick={() => {
              openNewPlanModal({...item, isDuplicate: true}, dispatch)
            }}>Duplicate Group Plan</span>
          }
        ])
        const studentsOnPlan = item?.studentPlans
        const studentList = []
        //recorrer el array studentsOnPlan con un for
        for (let i = 0; i < studentsOnPlan?.length; i++) {
          const student = studentsOnPlan[i];
          if(student?.planStatus !== 'cancel') {
            studentList.push(student)
          }
        }
        if (studentList?.length === 0) {
          options = (options || []).concat([
            {
              component: <span onClick={() => {
                openDeletePlanModal(() => {
                  refetch()
                  closeDeletePlanModal()
                }, item, true /**useMutation**/)
              }}>{translate('plans.actions.deleteGroupPlan')}</span>
            }
          ])
        }
      }
      break
    case 'sent':
      if (item.kind !== 'group') {
        mainBtnConfig.text = translate('plans.actions.resend')
        mainBtnConfig.onClick = () => redirectToSendOnePlan(item, refetch, dispatch)
        options = [
          {
            component: <span onClick={() => redirectToSendOnePlan(item, refetch, dispatch)}>{translate('plans.actions.resend')}</span>
          },
          {
            component: <span onClick={() => { 
              editPlan(item, false)
            }}>{translate('plans.actions.edit')}</span>
          },
          {
            component: <span 
              className={item?.groupId ? `text-gray hover-disabled` : ``} 
              onClick={() => { 
                if(!item?.groupId) editPlan(item, true) 
              }}>Duplicate plan</span>
          },
          {
            component: <span onClick={() => { redirectToCancelOrPause('cancel', item, dispatch, refetch) }}>{translate('plans.actions.cancel')}</span>
          },
          {
            component: <span onClick={() => { openPlanModal(item, extraConfig, dispatch) }}>{translate('plans.actions.view')}</span>
          },
          {
            component: <span onClick={() => { 
              window.open(`${process.env.LIVEPREP_URL || 'http://dev.liveprep.zinkerz.com'}/onboarding/${item.id}?isAdmin=true`, '_blank')
            }}>{translate('plans.actions.onboarding')}</span>
          }
        ]
      } else {

        mainBtnConfig.text = translate('plans.actions.edit')
          mainBtnConfig.onClick = () => openNewPlanModal(item, dispatch)

          options = [
            {
              component: <span onClick={() => { redirectToSendAllPlan(item, refetch, dispatch) }}>{translate('plans.actions.sendAll')}</span>
            },
          ]
        
        options = (options || []).concat([
          {
            component: <span onClick={() => {
              openNewPlanModal({...item, isDuplicate: true}, dispatch)
            }}>Duplicate Group Plan</span>
          }
        ]) 
      }
      break
    case 'scheduled':
      if (item.kind !== 'group') {
        mainBtnConfig.text = translate('plans.actions.view')
        mainBtnConfig.onClick = () => openPlanModal(item, extraConfig, dispatch)
        options = [
          {
            component: <span onClick={() => openPlanModal(item, extraConfig, dispatch)}>{translate('plans.actions.view')}</span>
          },
          {
            component: <span>{translate('plans.actions.adjust')}</span>
          },
          {
            component: <span
              className={item?.groupId ? `text-gray hover-disabled` : ``}
              onClick={() => {
                if(!item?.groupId) editPlan(item, true)
              }}
            >Duplicate Plan</span>
          },
          {
            component: <span onClick={() => {
              openCompletePlanModal(() => {
                refetch()
                closeCompletePlanModal()
              }, item, true /**useMutation**/)
            }}>Complete plan</span>
          },
          // {
          //   component: <span onClick={() => { redirectToCancelOrPause('pause', item, dispatch, refetch) }}>{translate('plans.actions.pause')}</span>
          // },
          {
            component: <span onClick={() => { redirectToCancelOrPause('cancel', item, dispatch, refetch) }}>{translate('plans.actions.cancel')}</span>
          }
        ]
      } else {
        mainBtnConfig.text = translate('plans.actions.view')
        mainBtnConfig.onClick = () => viewGroupPlan(item, extraConfig, dispatch)
        options = (
          [{
            component: <span onClick={() => openNewPlanModal(item, dispatch)}>{translate('plans.actions.edit')}</span>
          }] 
          ).concat([
          {
            component: <span onClick={() => {
              openCompleteGroupPlan(() => {}, item, true /**useMutation**/)
            }}>Complete Group Plan</span>
          },
          {
            component: <span onClick={() => {
              openNewPlanModal({...item, isDuplicate: true}, dispatch)
            }}>Duplicate Group Plan</span>
          },
          // {
          //   component: <span onClick={
          //     () => { 
          //       openDeletePlanModal(() => {
          //         refetch()
          //         closeDeletePlanModal()
          //       }, item, true /**useMutation**/)
          //     }
          //   }>{translate('plans.actions.cancel')}</span>
          // },
          // {
          //   component: <span onClick={
          //     () => { 
          //       openDeletePlanModal(() => {
          //         refetch()
          //         closeDeletePlanModal()
          //       }, item, true /**useMutation**/)
          //     }
          //   }>{translate('plans.actions.cancel')}</span>
          // },
          {
            component: <span onClick={() => {
              getGroupPlan({
                variables: {planId: item.id}
              }).then(
                (res) => {
                  const plan = res.data.getGroupPlan
                  const lpw = parseLpwGroupPlan(plan, localTime)
                  openReviewScheduledModal((excludeDays) => {
                    //ejecutar mutación
                    // setLoading(true)
                    setLoadingReviewModal(true)
                    removeGroupLessons({
                      variables: {
                        planId: plan.id,
                        excludeDays: excludeDays.map((date) => {
                          return moment(date).utc().format('YYYY-MM-DD') 
                        })
                      }
                    })
                  }, reviewProps({planId: plan.id, lpw}, true /**showRealLessons**/))
                },
                (err) => {
                  showAlert({
                    text: err.toString(),
                    status: 'error'
                  }, dispatch)
                }
              )
              
            }}>{loadGroupPlanData ? <IconLoad className={`mr-2`}/> : null}<>{translate('plans.actions.reviewshedule')}</></span>
          }
        ])
        const studentsOnPlan = item?.studentPlans
        const studentList = []
        //recorrer el array studentsOnPlan con un for
        for (let i = 0; i < studentsOnPlan?.length; i++) {
          const student = studentsOnPlan[i];
          if(student?.planStatus !== 'cancel') {
            studentList.push(student)
          }
        }
        if (studentList?.length === 0) {
          options = (options || []).concat([
            {
              component: <span onClick={() => {
                openDeletePlanModal(() => {
                  refetch()
                  closeDeletePlanModal()
                }, item, true /**useMutation**/)
              }}>{translate('plans.actions.deleteGroupPlan')}</span>
            }
          ])
        }
      }
      break
    case 'paid':
      mainBtnConfig.text = translate('plans.actions.view')
      mainBtnConfig.onClick = () => openPlanModal(item, extraConfig, dispatch)
      options = [
        {
          component: <span onClick={() => openPlanModal(item, extraConfig, dispatch)}>{translate('plans.actions.view')}</span>
        }
      ]
      break
    case 'cancel':
      mainBtnConfig.text = translate('plans.actions.view')
      mainBtnConfig.onClick = () => openPlanModal(item, extraConfig, dispatch)
      options = [
        {
          component: <span onClick={() => openPlanModal(item, extraConfig, dispatch)}>{translate('plans.actions.view')}</span>
        }
      ]
      break
    case 'pause':
      mainBtnConfig.text = translate('plans.actions.resume')
      mainBtnConfig.onClick = () => redirectToResumePlan(item, refetch, dispatch)
      options = [
        {
          component: <span onClick={() => { redirectToResumePlan(item, refetch, dispatch) }}>{translate('plans.actions.resume')}</span>
        },
        {
          component: <span onClick={() => { redirectToCancelOrPause('cancel', item, dispatch, refetch) }}>{translate('plans.actions.cancel')}</span>
        },
        {
          component: <span onClick={() => openPlanModal(item, extraConfig, dispatch)}>{translate('plans.actions.view')}</span>
        }
      ]
      break
    case 'finished':
      mainBtnConfig.text = translate('plans.actions.view')
      mainBtnConfig.onClick = () => openPlanModal(item, extraConfig, dispatch)
      options = [
        {
          component: <span onClick={() => openPlanModal(item, extraConfig, dispatch)}>{translate('plans.actions.view')}</span>
        }
      ]
      break  
    default:
      mainBtnConfig.text = ''
      options = []
      break
  }



  if(item?.groupId !== null && ["draft", "sent", "scheduled"].includes(item.status)) {
    options.push(
      {
        component:
        <span onClick={() => { 
          openMoveStudenModal(
            (groupPlan) => {
              closeMoveStudentModal()
              refetch()
            }, item, true /**useMutation**/
          )
        }}>Move student</span>
      } 
    )
  }

  // if(item?.groupId !== null && ["draft", "sent", "scheduled"].includes(item.status)) {
  //   options.push(
  //     {
  //       component: <span 
  //       onClick={
  //         () => { 
  //           openDeletePlanModal(() => {
  //             refetch()
  //             closeDeletePlanModal()
  //           }, item, true /**useMutation**/)
  //           // redirectToCancelOrPause('delete', item, dispatch, refetch) 
  //         }
  //       }>{translate('plans.actions.delete')}</span>
  //     }
  //   )
  // }
  return {
    mainBtnConfig,
    options
  }
}

export default TableSelectorField
