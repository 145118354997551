import useGetInvoiceLink from "../../../Model/Payments/GetInvoiceLink"
import useCopyText from '../../../common/CopyText/useCopyText'
import useTranslation from "../../../i18n/useTranslation"
import { showAlert } from "../../../common/Alert/util"
import { useDispatch } from "react-redux"

const useCopyInvoiceLink = () => {
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    const [getInvoiceLink] = useGetInvoiceLink()
    const [copyText, setCopyText] = useCopyText()
    const [copySuccess, copyError] = [
        (text) => {
            //success
            showAlert({ text: translate("payments.invoiceLink.copySuccess"), status: 'success' }, dispatch)
        },
        (error, text) => {
            //error
            showAlert({ text: `${translate("payments.invoiceLink.copyError")} \n ${copyText}`, status: 'error' }, dispatch)
        }
    ]
    const copyInvoiceLink = (invoiceId) => {
        getInvoiceLink({ variables: {invoiceId} }).then(
          (resp) => {
            const linkToInvoice = `${process.env.REACT_APP_LIVEPREP_URL || 'http://liveprep.zinkerz.com'}/profile/payments?invoiceId=${invoiceId}&nologin=1&token=${resp?.data?.getInvoiceLink}`
            setCopyText(linkToInvoice, copySuccess, copyError)
          },
          (error) => {
            console.error(error);
          },
        )
    }
    return {copyInvoiceLink}
}

export default useCopyInvoiceLink