import React, { useEffect, useState } from 'react'
import {
  Field,
  SelectOption,
  PlainTextInput,
  InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import NewActivity from './NewActivity'
import moment from 'moment-timezone'
import escapeRegExp from 'lodash/escapeRegExp'
import { ordenateArrayByName } from '../../../../../utils/ordenateArrayByName'
const PlanDetails = (props) => {
  const { 
    data, form, setForm, isEdit, 
    disableTypePlan = false, min = false, /**no muestra el campo de location**/ 
    isTimelinePlan = false
  } = props
  const { translate } = useTranslation()
  const [isFirtsRenderEdit, setIsFirtsRenderEdit] = useState(isEdit)
  useEffect(() => {
    if (!isFirtsRenderEdit) {
      // para no sobreescribir el test date la primera vez que se renderiza
      // y se está editando el plan
      // lpw: {0: { partname: 'Part 1' }} }
      const newLpw = Object.entries(form.lpw).reduce((newLpw, [key, value]) => {
        let obj = {}
        if (value.partname) obj = { ...obj, partname: value.partname }
        if (value.startdate) obj = { ...obj, startdate: value.startdate }
        if (value.enddate) obj = { ...obj, enddate: value.enddate }
        return {
          ...newLpw,
          [key]: obj
        }
      }, {})
      setForm({
        ...form,
        testdate: null,
        lpw: newLpw
      })
    }
    setIsFirtsRenderEdit(false)
    // eslint-disable-next-line
  }, [form.idservice])

  useEffect(() => {
    if (form.typePlan === 'time' && !isEdit) {
      setForm({
        ...form,
        typeLesson: 'totalLesson'
      })
    }
    // eslint-disable-next-line
  }, [form.typePlan])

  const setTestDate = (testdate) => {
    let thisServices = data.docs.find((serv) => serv.id === testdate.serviceId)
    setForm({
      ...form,
      planname: (form.planname === undefined || form.planname === '')
        ? `${thisServices?.name} - ${moment(testdate.startDate).utc().format('MMM Do, YYYY')}` : form.planname,
      testdate: {
        date: testdate.startDate,
        name: testdate.name,
        serviceId: testdate.serviceId,
        activityId: testdate.id,
        releaseScoresDate: testdate.scoreReleaseDate,

      }
    })
  }

  return (
    <>
      <div className={`pl-0 pr-6 py-6 pl-3`}>
        <h3><b>{translate('modal.newplan.plandetails')}</b></h3>
        <div className='row pl-3'>
          <label className='col-12 text-muted mb-2 mt-3 pl-0'>
            {translate('modal.newplan.plantype')}
          </label>
          <Field
            className='col-12 row inline'
            type={SelectOption}
            fieldName='typePlan'
            options={[
              { id: 'date', text: 'Test date' },
              { id: 'time', text: 'Timeless' }
            ]}
            value={form.typePlan}
            StyleOptionClassName={{
              width: '49%',
              whiteSpace: 'nowrap'
            }}
            StyleSelect={{
              justifyContent: 'space-between'
            }}
            isDisabled={disableTypePlan || isTimelinePlan}
          />
          <label className='col-12 text-muted mb-2 mt-3 pl-0'>
            {translate('modal.newplan.plandetails.service')}
          </label>
          <Field
            className='col-12 p-0'
            type={InputSelect}
            fieldName='idservice'
            placeholder={translate('modal.newplan.selectservice')}
            options={data && data.docs && [{ value: '0', label: translate('modal.newplan.selectservice') }].concat(ordenateArrayByName(data.docs).map((serv) => ({ value: serv.id, label: serv.name })))}
            InputClassName='w-100'
            value={form.idservice}
            filterOption={(obj, string) => {
              if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
              return false
            }}
            menuPlacement={'auto'}
          />
          {
            form.typePlan !== 'time' &&
              <>
                <label className='col-12 text-muted mb-2 mt-5 pl-0'>
                  {translate('modal.newplan.testdate')}
                </label>
                {form.idservice && form.idservice !== '0'
                  ? <NewActivity
                      placeholder={translate('modal.newplan.testdate')}
                      fieldName='testdate'
                      setTestDate={setTestDate}
                      idService={form.idservice}
                      nameService={(data?.docs || []).filter((serv) => serv.id === form.idservice)[0]?.name}
                    >
                    <label className='py-1 btn-link'>{form.testdate ? form.testdate.name : translate('modal.newplan.plandetails.selecttestdate')}</label>
                  </NewActivity>
                  : <label className='text-muted'>{translate('modal.newplan.selectservice')}</label>}
              </>
          }
          <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
            {translate('modal.newplan.studyplanname')}
          </label>
          <Field
            className='col-12'
            type={PlainTextInput}
            fieldName='planname'
            placeholder={translate('modal.newplan.enterplanname')}
            value={form.planname}
          />
          {
            !min && (
              <>
                <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                  {translate('modal.newplan.location')}
                </label>
                <Field
                  className='col-12'
                  type={SelectOption}
                  fieldName='locationPlan'
                  showNotSelected
                  changeFunction={() => {
                    setForm({ ...form, locationPlan: null })
                  }}
                  changeLabel={translate('modal.newplan.changelocation')}
                  options={[
                    { id: 'virtualClassroom', text: 'Virtual Classroom' },
                    { id: 'zinkerzPlatform', text: translate('modal.newplan.plandetails.zinkerzp') },
                    { id: 'hangouts', text: 'Meets' },
                    { id: 'zoom', text: 'Zoom' },
                    { id: 'inPerson', text: translate('modal.newplan.plandetails.inperson') }
                  ]}
                  value={form.locationPlan}
                />
                {['inPerson'].includes(form.locationPlan) && (
                  <>
                    <label className='col-12 text-muted mt-2 pl-0'>
                      {form.locationPlan === 'inPerson' ? translate('modal.newplan.address') : translate('modal.newplan.locationlink')}
                    </label>
                    <Field
                      className='col-12'
                      type={PlainTextInput}
                      fieldName='locationPlanLink'
                      placeholder={form.locationPlan === 'inPerson' ? translate('modal.newplan.address') : 'http://zoom.com/ASDF'}
                    />
                  </>
                )}
              </>
            ) 
          }
        </div>
      </div>
      <div className='borderTop ml-3' />
    </>
  )
}
PlanDetails.propTypes = {
}
PlanDetails.defaultProps = {
}
export default PlanDetails
